import { getSupportData } from "../services/services"
import * as language from '../constants/languages'
import Addressicon from '../assets/svg/aboutus/addressicon.svg'
import Email from '../assets/svg/aboutus/email.svg'
import Phone from '../assets/svg/aboutus/phone.svg'
import Facebook from '../assets/svg/facebook.svg'
import InstaGram from '../assets/svg/instagram.svg'
import LinkedIn from '../assets/svg/linkedin.svg'
import YouTube from '../assets/svg/youtube.svg'
import CompanyLogo from "../assets/header_logo.png";
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import '../styles/FooterComponent.css'
import { ReactSVG } from "react-svg"
export default function FooterComponent() {
    const navigate = useNavigate()
    const location = useLocation()
    const [number, setnumber] = useState()
    const [email, setemail] = useState()
    const [contactus, setcontactus] = useState()
    const [contactData, setcontactData] = useState()
    const [logo, setlogo] = useState()
    useEffect(() => {
        getSupportInfoData()
        getCompanyInfo()
    }, [])
    async function getCompanyInfo() {
        const value = localStorage.getItem('companyInfo')
        if (value) {
            let companyInfo = JSON.parse(value);
            const Logo = companyInfo.logo !== "" ? companyInfo.logo : CompanyLogo;
            setlogo(Logo)
        }

    }

    async function getSupportInfoData() {
        await getSupportData(language.companyidValue).then((supportData) => {
            if (supportData.status === 'success') {

                if (supportData.data.contactNos != null) {
                    if (supportData.data.contactNos.length > 1) {
                        setnumber(supportData.data.contactNos.split(','))
                    } else {
                        setnumber(supportData.data.contactNos)
                    }
                } else {
                    setnumber([])
                }
                if (supportData.data.contactEmail != null) {
                    if (supportData.data.contactEmail.length > 1) {
                        setemail(supportData.data.contactEmail.split(','))
                    } else {
                        setemail(supportData.data.contactEmail)
                    }
                } else {
                    setemail([])
                }
                if (supportData.data.contactus != null) {
                    if (supportData.data.contactus.length > 1) {
                        setcontactus(supportData.data.contactus.split(','))

                    } else {
                        setcontactus(supportData.data.contactus)
                    }
                } else {
                    setcontactus([])

                }
                setcontactData(supportData)
            }
        })
    }
    return (
        <div style={{ marginTop: '5rem' }}>
            <div style={{ display: 'flex', flex: 1, paddingBlock: '3rem', backgroundColor: '#F1F1F1' }}>
                <div style={{ marginRight: '2rem', marginLeft: "1rem", display: 'flex', alignItems: 'flex-start', marginTop: '4rem' }}>
                    {CompanyLogo && <img src={CompanyLogo} height='auto' width={70} />}
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', marginTop: '1rem', }}>
                    <div className='footer-navigation-container' onClick={() => navigate('/')}>
                        <p className='NavigationMenuTitle' style={{ color: location.pathname === '/' && '#f79723' }}>Home</p>
                    </div>
                    <div className='footer-navigation-container' onClick={() => navigate('/Categories')}>
                        <p className='NavigationMenuTitle' style={{ color: location.pathname === '/Categories' && '#f79723' }}>Categories</p>
                    </div>
                    <div className='footer-navigation-container' onClick={() => navigate('/Offers')}>
                        <p className='NavigationMenuTitle' style={{ color: location.pathname === '/Offers' && '#f79723' }}>Offers</p>
                    </div>
                    <div className='footer-navigation-container' onClick={() => navigate('/About')}>
                        <p className='NavigationMenuTitle' style={{ color: location.pathname === '/About' && '#f79723' }}>About Us</p>
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 2, marginInline: 30, flexDirection: 'column' }}>
                    <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 20, textDecoration: 'underline' }}>Contact Info</p>
                    {
                        contactus?.length > 0 &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <img src={Addressicon} />
                                </div>
                                <div style={{ marginLeft: 15, flex: 1 }}>
                                    <p style={{ fontFamily: 'Montserrat-Medium', marginBottom: 10, }}>Address</p>

                                    <div style={{ fontFamily: 'Montserrat-Regular', }}  dangerouslySetInnerHTML={{ __html: contactus }} />
                                </div>
                            </div>
                        </>
                    }
                    {
                        number?.length > 0 &&
                        <div style={{ marginTop: 30, display: 'flex', marginLeft: 30 }}>
                            <div >
                                <img src={Phone} />
                            </div>
                            <div style={{ marginLeft: 15, flex: 1 }}>
                                <p style={{ fontFamily: 'Montserrat-Medium', marginBottom: 10, }}>Phone</p>
                                {
                                    number?.map((number, index) => (
                                        <p key={index} style={{ fontFamily: 'Montserrat-Regular', }}>{number}</p>

                                    ))
                                }
                            </div>

                        </div>
                    }

                    {
                        email?.length > 0 &&
                        <div style={{ marginTop: 30, display: 'flex', marginLeft: 60 }}>
                            <div >
                                <img src={Email} />

                            </div>
                            <div style={{ marginLeft: 15, flex: 1 }}>
                                <p style={{ fontFamily: 'Montserrat-Medium', margin: 0 }}>Email</p>
                                {
                                    email.map((email, index) => (
                                        <p key={index} style={{ fontFamily: 'Montserrat-Regular', }}>{email}</p>

                                    ))
                                }
                            </div>
                        </div>

                    }
                </div>
                <div style={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
                    <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 20, textDecoration: 'underline' }}>Follow Us</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            contactData?.data?.facebook && <ReactSVG src={Facebook} className='footer-social-media' onClick={() => window.open(contactData?.data?.facebook)} />
                        }
                        {
                            contactData?.data?.instagram && <ReactSVG src={InstaGram} className='footer-social-media' onClick={() => window.open(contactData?.data?.instagram)} />
                        }
                        {
                            contactData?.data?.linkedin && <ReactSVG src={LinkedIn} className='footer-social-media' onClick={() => window.open(contactData?.data?.linkedin)} />
                        }
                        {
                            contactData?.data?.youtube && <ReactSVG src={YouTube} className='footer-social-media' onClick={() => window.open(contactData?.data?.youtube)} />
                        }
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flex: 1, backgroundColor: '#E9E9E9', paddingBlock: '2rem', alignItems: 'center', justifyContent: 'center', fontFamily: 'Poppins-Regular' }}>
                <p style={{ margin: 0 }}>Copyright 2024 © Xenia All Rights reserved</p>
            </div>
        </div>
    )
}